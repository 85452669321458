<template>
  <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 500 500">
    <path
      stroke="#9b98d1"
      stroke-width="2.00"
      fill="none"
      stroke-linecap="butt"
      vector-effect="non-scaling-stroke"
      d="
  M 287.10 94.33
  L 162.23 372.26
  A 0.33 0.33 12.5 0 0 162.53 372.73
  L 212.21 372.70
  A 0.79 0.76 -77.3 0 0 212.91 372.24
  L 337.33 94.56
  A 0.34 0.32 -78.0 0 0 337.04 94.09
  L 287.55 94.03
  A 0.50 0.49 -78.0 0 0 287.10 94.33"
    />
    <path
      fill="#3730a3"
      d="
  M 0.00 0.00
  L 500.00 0.00
  L 500.00 500.00
  L 0.00 500.00
  L 0.00 0.00
  Z
  M 287.10 94.33
  L 162.23 372.26
  A 0.33 0.33 12.5 0 0 162.53 372.73
  L 212.21 372.70
  A 0.79 0.76 -77.3 0 0 212.91 372.24
  L 337.33 94.56
  A 0.34 0.32 -78.0 0 0 337.04 94.09
  L 287.55 94.03
  A 0.50 0.49 -78.0 0 0 287.10 94.33
  Z"
    />
    <path
      fill="#ffffff"
      d="
  M 287.10 94.33
  A 0.50 0.49 -78.0 0 1 287.55 94.03
  L 337.04 94.09
  A 0.34 0.32 -78.0 0 1 337.33 94.56
  L 212.91 372.24
  A 0.79 0.76 -77.3 0 1 212.21 372.70
  L 162.53 372.73
  A 0.33 0.33 12.5 0 1 162.23 372.26
  L 287.10 94.33
  Z"
    />
  </svg>
</template>
